import "../assets/styles/pages/home.scss"

import React from "react"
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Index = () => {
  return (
    <Layout>
      <Helmet>
        <script
          src={withPrefix("imagerotator.js")}
          type="text/javascript"
          defer
        />
      </Helmet>
      <Seo title="Home" />

      <section className="home">
        <div className="home__rotator">
          <div id="wr360PlayerId" className="wr360_player"></div>
        </div>

        <div className="home__logo">
          <img
            src={require("../assets/images/logo-hot-wheels-rc.svg").default}
            alt=""
          />
          <img
            src={require("../assets/images/logo-cybertruck.svg").default}
            alt=""
          />
        </div>
        <div className="home__action">
          <h3>
            <strong>
              Welcome to the Official 1/10th RC Cybertruck Interactive Support
              Resource
            </strong>
            Simply click the link below to navigate the trucks blueprints
          </h3>
          <Link to="/interactive-support/">Go To support</Link>
        </div>
      </section>
    </Layout>
  )
}

export default Index
